import React, { useEffect, useRef, useState } from 'react';
import { configSlideCertified } from '../../provider/dataconfig'; 
import { isMobile } from "react-device-detect";
import styles from '../../styles/certified.module.css';


import doc1 from '../../assets/documents/enfoques/ENFOQUE SERVICIO AL CLIENTE.pdf'
import doc2 from '../../assets/documents/enfoques/ENFOQUE VIGILANTE.pdf'
import doc3 from '../../assets/documents/enfoques/ENFOQUE SUPERVISOR.pdf'
import doc4 from '../../assets/documents/enfoques/ENFOQUE ESCOLTA.pdf'
import doc5 from '../../assets/documents/enfoques/ENFOQUE OMT.pdf'

export const esquemasCertificacion = [
    {
        id: 'atencion-servicio',
        img: '/images/Cetified_1.png',
        title: 'Persona competente en atención y servicio al cliente',
        description: 'Este esquema abarca una amplia gama de actividades relacionada con la atención y servicio al cliente, principalmente los criterios de conocimiento y desempeño especificados en la norma de referencia bajo los cuales determinamos que la persona es capaz de desenvolverse en diversos cargos en los que se requiera una interacción directa con el consumidor.',
        titleModal : 'PERSONA COMPETENTE EN ATENCIÓN Y SERVICIO AL CLIENTE',
        document: doc1
    },
    {
        id: 'vigilante',
        img: '/images/Cetified_2.png',
        title: 'Vigilante competente en servicios de seguridad privada',
        description: 'Evaluamos la pericia demostrada por la persona para desempeñarse de manera eficaz en su rol como Vigilante al aplicar los criterios de la Normativa, en especial lo que tiene que ver con: Control de accesos, Prevenir incidentes y Atender situaciones de crisis, así como los conocimientos aplicados para el desarrollo efectivo de la labor.',
        titleModal : 'VIGILANTE COMPETENTE EN SERVICIOS DE SEGURIDAD PRIVADA',
        document: doc2
    },
    {
        id: 'supervisor',
        img: '/images/Cetified_3.png',
        title: 'Supervisor competente en servicios de vigilancia y seguridad privada',
        description: 'Mediante la aplicación de este esquema la persona deberá demostrar que cuenta con las habilidades necesarias para supervisar los servicios de seguridad y vigilancia mediante la demostración de evidencias de conocimiento y desempeño según la norma de referencia.',
        titleModal : 'SUPERVISOR COMPETENTE EN SERVICIOS DE VIGILANCIA Y SEGURIDAD PRIVADA',
        document: doc3
    },
    {
        id: 'escolta',
        img: '/images/Cetified_4.png',
        title: 'Escolta competente en protección de personas',
        description: 'Esquema de certificación diseñado para constatar que la persona tiene la capacidad para desempeñar su rol en la protección de personas, principalmente idónea para minimizar cualquier situación de riesgo identificada al momento de ejercer su labor.',
        titleModal : 'ESCOLTA COMPETENTE EN PROTECCIÓN DE PERSONAS',
        document: doc4
    },
    {
        id: 'operador',
        img: '/images/Cetified_5.png',
        title: 'Operador de medios tecnológicos competente en vigilancia y seguridad privada',
        description: 'La persona que desee certificar su competencia deberá ser capaz de manejar herramientas tecnológicas, cámaras de seguridad, analizar la información que hay detrás de ellas y predecir los futuros eventos de riesgo que puedan presentarse.',
        titleModal : 'OPERADOR DE MEDIOS TECNOLÓGICOS COMPETENTE EN VIGILANCIA Y SEGURIDAD PRIVADA',
        document: doc5
    }
]


const ModalCertified = ({ show, onClose, titleModal, document}) => {
    if (!show) return null;

    return (
        <div className={styles.modalOverlayCertified}>
            <div className={styles.modalContent}>
                <button onClick={onClose} className={styles.closeButton}>X</button>
                <div className={styles.modalTitle}>
                    <h6>ENFOQUE FUNCIONAL DEL ESQUEMA DE CERTIFICACIÓN</h6>
                    <h6>{titleModal}</h6>
                </div>
                <iframe
                    src={`${document}#toolbar=0&navpanes=0&scrollbar=0`}
                    style={{ width: "100%", height: "500px", border: "none" }}
                ></iframe>
            </div>
        </div>
    );
}

const Certified = ()=>{
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const swiperElRef = useRef(null);

    useEffect(() => {
        // listen for Swiper events using addEventListener
        swiperElRef.current.addEventListener('progress', (e) => {
        const [progress] = e.detail;
        console.log(progress);
        });

        swiperElRef.current.addEventListener('slidechange', (e) => {
        console.log('slide changed');
        });
    }, []);

    // Función para abrir el modal con los datos correspondientes
    const handleOpenModal = (item) => {
        setModalData(item);
        setShowModal(true);
    };

    // Función para cerrar el modal
    const handleCloseModal = () => {
        setShowModal(false);
    };


    return(
        <>
            <section className={styles.main_slide}>
                <swiper-container
                    ref={swiperElRef}
                    slides-per-view="1"
                    navigation="true"
                    pagination="true"
                    >
                    {
                        configSlideCertified.map((slide)=>(
                            <swiper-slide>
                                <div className={styles.wrapper_contain_slide}>
                                    <img className={styles.img_main_slide} src={isMobile ? slide.imgMobile : slide.img} alt={slide.title_img} />
                                    <span className={styles.title_slide}>{slide.title}</span>
                                    <span className={styles.description_slide}>{slide.description}</span>
                                </div>
                            </swiper-slide>
                        ))
                    }
                </swiper-container>
            </section>

            <section className={styles.main_description}>
                <span className={styles.title}>Esquemas de Certificacion</span>
            </section>

            <section className={styles.certificaciones}>
                {esquemasCertificacion.map((itemCertified, index)=>(
                    <div key={itemCertified.title} className={styles.itemCertified} id={itemCertified.id}>
                    {index % 2 === 0 ? 
                        <>
                        <img src={itemCertified.img} alt={itemCertified.title}/>
                        <div className={styles.contentDscription}>
                            <span className={styles.title}>{itemCertified.title}</span>
                            <span className={styles.description}>{itemCertified.description}</span>
                            <button onClick={() => handleOpenModal(itemCertified)} className={styles.moreButton}>Ver más</button>
                        </div>
                        </>
                        :
                        <>
                        <div className={styles.contentDscription}>
                            <span className={styles.title}>{itemCertified.title}</span>
                            <span className={styles.description}>{itemCertified.description}</span>
                            <button onClick={() => handleOpenModal(itemCertified)} className={styles.moreButton}>Ver más</button>
                        </div>
                        <img src={itemCertified.img} alt={itemCertified.title} />
                        </>
                    }
                    </div>
                ))}
            </section>

            {/* Modal */}
            <ModalCertified 
                show={showModal} 
                onClose={handleCloseModal} 
                titleModal={modalData.titleModal} 
                document={modalData.document}
            />

        </>
    )
}

export default Certified