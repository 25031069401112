import './App.css';
import React from "react";
import AppCecol from './pages/AppCecol'
import './App.css';

function App() {
  return (
    <AppCecol/>
  );
}

export default App;
