import React, { useEffect, useState, useRef } from 'react'
import { configSlideAbout } from '../../provider/dataconfig'; 
import styles from '../../styles/home.module.css'
import { isMobile } from "react-device-detect";
import doc1 from '../../assets/documents/PROCEDIMIENTO CONFIDENCIALIDAD Y TRATAMIENTO DE LA INFORMACION.pdf';
import doc2 from '../../assets/documents/REGLAMENTO USO DE CERTIFICADOS, LOGOTIPOS Y MARCAS.pdf';
import doc3 from '../../assets/documents/DESCRIPCION DEL PROCESO DE CERTIFICACION.pdf';
import doc4 from '../../assets/documents/PROCEDIMIENTO SUSPENSIÓN, RETIRO O REDUCCIÓN DEL ALCANCE DE CERTIFICACIÓN.pdf';
import doc5 from '../../assets/documents/PROCEDIMIENTO QUEJAS Y APELACIONES.pdf';
import doc6 from '../../assets/documents/REGLAMENTO DEL PROCESO DE EVALUACION Y CERTIFICACION.pdf';
import '../../styles/consultation/consultation.css';

const documentsContent = [
  { title: 'PROCEDIMIENTO CONFIDENCIALIDAD Y TRATAMIENTO DE LA INFORMACION', file: doc1 },
  { title: 'REGLAMENTO USO DE CERTIFICADOS, LOGOTIPOS Y MARCAS', file: doc2 },
  { title: 'DESCRIPCION DEL PROCESO DE CERTIFICACION', file: doc3 },
  { title: 'PROCEDIMIENTO SUSPENSIÓN, RETIRO O REDUCCIÓN DEL ALCANCE DE CERTIFICACIÓN', file: doc4 },
  { title: 'PROCEDIMIENTO QUEJAS Y APELACIONES', file: doc5 },
  { title: 'REGLAMENTO DEL PROCESO DE EVALUACION Y CERTIFICACION', file: doc6 }
]

const Consultation = () => {
  const [activeKey, setActiveKey] = useState(null);

  const toggleActiveKey = (index) => {
    if (activeKey === index) {
      setActiveKey(null); // Cierra el acordeón si ya está abierto
    } else {
      setActiveKey(index); // Abre el acordeón
    }
  };

  // Slide
  const swiperElRef = useRef(null);
  useEffect(() => {
      // listen for Swiper events using addEventListener
      swiperElRef.current.addEventListener('progress', (e) => {
      const [ progress ] = e.detail;
      console.log(progress);
      });

      swiperElRef.current.addEventListener('slidechange', (e) => {
      console.log('slide changed');
      });
  }, []);

  return (
    <div className="ConsultationDocumentsContainer mb-5">

      <section className={styles.main_slide}>
          <swiper-container
              ref={swiperElRef}
              slides-per-view="1"
              navigation="true"
              pagination="true"
              >
              {
                  configSlideAbout.map((slide)=>(
                      <swiper-slide>
                          <div className={styles.wrapper_contain_slide}>
                              <img className={styles.img_main_slide} src={isMobile ? slide.imgMobile : slide.img} alt={slide.title_img} />
                              <span className={styles.title_slide}>{slide.title}</span>
                              <span className={styles.description_slide}>{slide.description}</span>
                          </div>
                      </swiper-slide>
                  ))
              }
          </swiper-container>
      </section>

      <div className="container mt-5">
        <h1>Documentos de Consulta</h1>
        <div className="accordion mt-3" id="accordionExample">
          {documentsContent.map((doc, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${activeKey === index ? "" : "collapsed"}`}
                  type="button"
                  onClick={() => toggleActiveKey(index)}
                  aria-expanded={activeKey === index}
                  aria-controls={`collapse${index}`}
                >
                  {doc.title}
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                className={`accordion-collapse collapse ${activeKey === index ? "show" : ""}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  {activeKey === index && (
                    <iframe
                      src={`${doc.file}#toolbar=0&navpanes=0&scrollbar=0`}
                      style={{ width: "100%", height: "500px", border: "none" }}
                      title={doc.title}
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Consultation };
